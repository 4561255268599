//* /MP/salesfinder/src/store/keyword.js

import Vue from "vue";
export default {
    namespaced: true,
    actions: {

        //*===================================
        async getKeywordLimit(context, {fake}) {
            const { data } = await Vue.api.get("/getKeywordLimit", {
                params: {
                    fake
                },
            });
            return data;
        },


        //*===============================================================
        async getKeyword(context, { k_id, mp, date, date2, fbs, kwd }) {
            const { data } = await Vue.api.get("/getKeyword", {
                params: {
                    mp,
                    date,
                    date2,
                    k_id,
                    fbs,
                    kwd,
                },
            });
            return data;
        },

        async getKeywordByName(context, { query, mp }) {
            const { data } = await Vue.api.get("/getKeywordByName", {
                params: {
                    query,
                    mp,
                },
            });
            return data;
        },

        async keywordOverviewAll(context, queryParams) {
            const { data } = await Vue.api.get("/keywordOverviewAll", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },
        /*
        async keywordOverviewAll(context, { mp, k_id, date, date2, fbs, type, snap, kwd }) {
            const { data } = await Vue.api.get("/keywordOverviewAll", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    fbs,
                    type, snap, kwd,
                    // like _CH_DROP_CACHE_
                    ...queryParams,
                },
            });
            return data;
        },
        */

        async getKeywordProducts(context, queryParams) {
            const { mp, k_id, date, date2 } = queryParams;

            //console.log('48: getKeywordProducts.queryParams=',queryParams)

            const { data } = await Vue.api.get("/getKeywordProducts", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        async getKeywordBrands(context, queryParams) {
            const { mp, k_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getKeywordBrands", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        async getKeywordSellers(context, queryParams) {
            const { mp, k_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getKeywordSellers", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        async getKeywordLike(context, queryParams) {
            const { mp, k_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getKeywordLike", {
                params: {
                    mp,
                    k_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
    },
};
