import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async getSettings(context, { table_id }) {
            const { data } = await Vue.api.get("/table/getSettings", {
                params: {
                    table_id,
                },
            });
            return data;
        },
        async putSettings(context, { table_id, data }) {
            const { data: responseData } = await Vue.api.put(
                "/table/putSettings",
                {
                    table_id,
                    data,
                }
            );
            return responseData;
        },
        async getFilterPresetList(context, { table_id }) {
            const { data } = await Vue.api.get("/table/getFilterPresetList", {
                params: {
                    table_id,
                },
            });
            return data;
        },
        async getFilterPreset(context, { table_id, preset_title }) {
            const { data } = await Vue.api.get("/table/getFilterPreset", {
                params: {
                    table_id,
                    preset_title,
                },
            });
            return data;
        },
        async putFilterPreset(context, { table_id, preset_title, data }) {
            const { responseData } = await Vue.api.put(
                "/table/putFilterPreset",
                {
                    table_id,
                    preset_title,
                    data,
                }
            );
            return responseData;
        },
    },
};
