//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    dark: {
      value: false
    }
  }
};