//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: "green"
    }
  }
};