//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PluginBtn from "./PluginBtn.vue";
import NewFeature from "./NewFeature.vue";
export default {
  components: {
    PluginBtn,
    NewFeature
  },
  data() {
    let menu = [{
      icon: "dashboard.svg",
      title: "Главная",
      route: "/dashboard"
    },
    //==== WILDBERRIES
    {
      icon: "wb.svg",
      title: "Wildberries",
      route: "",
      part: "wb"
    }, {
      icon: "",
      title: "Категории",
      route: "/wb/category/",
      part: "wb"
    }, {
      icon: "",
      title: "Товары",
      route: "/wb/product/",
      part: "wb"
    }, {
      icon: "",
      title: "Бренды",
      route: "/wb/brand/",
      part: "wb"
    }, {
      icon: "",
      title: "Продавцы",
      route: "/wb/seller/",
      part: "wb"
    }, {
      //&: KeywordAsEntity // ~ category
      icon: "",
      title: "Поисковые запросы",
      route: "/wb/keyword/",
      new: true,
      labelColor: "green",
      part: "wb"
    }, {
      icon: "",
      title: "Рейтинги",
      route: "/wb/ratings/",
      part: "wb"
    }, {
      icon: "ozon.svg",
      title: "OZON",
      route: "",
      part: "ozon"
    }, {
      icon: "",
      title: "Категории",
      route: "/ozon/category/",
      part: "ozon"
    }, {
      icon: "",
      title: "Товары",
      route: "/ozon/product/",
      part: "ozon"
    }, {
      icon: "",
      title: "Бренды",
      route: "/ozon/brand/",
      part: "ozon"
    }, {
      icon: "",
      title: "Продавцы",
      route: "/ozon/seller/",
      part: "ozon"
    }, {
      //&: Keyword  //&:KaE
      icon: "",
      title: "Поисковые запросы",
      route: "/ozon/keyword/",
      new: true,
      labelColor: "green",
      part: "ozon"
    }, {
      icon: "",
      title: "Рейтинги",
      route: "/ozon/ratings/",
      part: "ozon"
    },
    /*
    // {
     //     icon: 'compare.svg',
    //     title: 'Сравнение',
    //     route: '/'
    // },
    */

    //==== МОИ СПИСКИ
    {
      icon: "lists.svg",
      title: "Мои списки",
      route: "/lists"
      /*
      //         preventNavigation: () => {
      //       return !!this.$access.check('lists')
      //   },
      //   click: (e) => {
      //       const accessMessage = this.$access.check('lists')
      //       if (accessMessage) {
      //           alert(accessMessage)
      //           e.preventDefault()
      //       }
      //   }
      */
    }, {
      icon: "seo.svg",
      title: "SEO Анализ",
      route: "",
      part: "seo"
    }, {
      icon: "",
      title: "Мониторинг позиций",
      route: "/position-monitoring",
      part: "seo"
      // preventNavigation: () => true
    }, {
      icon: "",
      part: "seo",
      title: "Подбор запросов",
      route: "/queries-selection"

      //// title: "Подбор запросов (скоро)",
      //// route: "#",
      //// preventNavigation: () => true,
    }, {
      icon: "monitoring.svg",
      title: "Контроль изменений",
      route: "/listMs"
    }];

    //==== МОНИТОРИНГ РРЦ
    menu = menu.concat({
      icon: "rrc.svg",
      title: "Мониторинг РРЦ",
      //route: "/priceVarList",
      route: "/retail_price_monitoring/list",
      new: true,
      labelColor: "green"
    });
    menu = menu.concat([
    //==== МОИ МАГАЗИНЫ
    {
      icon: "shops.svg",
      title: "Мои магазины",
      route: "",
      part: "connections"
    }, {
      icon: "",
      title: "Обзор",
      route: "/highlights",
      part: "connections"
    }, {
      icon: "",
      title: "Заказы",
      route: "/orders",
      part: "connections"
    }, {
      icon: "",
      title: "Продажи",
      route: "/sales",
      part: "connections"
    }, {
      icon: "",
      title: "География продаж",
      route: "/sales-geography",
      part: "connections"
    }, {
      icon: "",
      title: "Товары",
      route: "/products",
      part: "connections"
    }, {
      icon: "",
      title: "Склад",
      route: "/storage",
      part: "connections"
    }, {
      icon: "",
      title: "Возвраты",
      route: "/Returns",
      part: "connections"
    }, {
      icon: "",
      title: "ABC Анализ",
      route: "/abcAnalysis",
      part: "connections"
    }, {
      icon: "",
      title: "Расчёт поставки",
      route: "/delivery-calculation",
      part: "connections"
    }, {
      icon: "",
      title: "Сравнение периодов",
      route: "/compare",
      part: "connections"
    }, {
      icon: "",
      title: "Финансовый анализ",
      route: "/financial-analysis",
      part: "connections"
    }, {
      icon: "",
      title: "Подключения",
      route: "/connections",
      part: "connections"
    },
    //==== УПРАВЛЕНИЕ ОТЗЫВАМИ
    {
      icon: "review-management.svg",
      title: "Управление отзывами",
      route: "",
      part: "review-management",
      //new: true,
      labelColor: "green"
    }, {
      icon: "",
      title: "Обзор",
      route: "/review-management-highlights",
      part: "review-management"
    }, {
      icon: "",
      title: "Сценарии ответов",
      route: "/review-management-scripts",
      part: "review-management"
    }, {
      icon: "",
      title: "Отзывы без ответа",
      route: "/review-management-reviews-new",
      part: "review-management"
    }, {
      icon: "",
      title: "Обработанные отзывы",
      route: "/review-management-reviews-archive",
      part: "review-management"
    }, {
      icon: "",
      title: "Статистика по категориям",
      route: "/review-management-categories",
      part: "review-management"
    }, {
      icon: "",
      title: "Подключения",
      route: "/review-management-connections",
      part: "review-management"
    },
    //==== РЕПРАЙСЕР
    {
      icon: "repricer.svg",
      title: "Репрайсер",
      route: "",
      part: "repricer",
      new: true,
      labelColor: "green"
    }, {
      icon: "",
      title: "Обзор",
      route: "/repricer-highlights",
      part: "repricer"
    }, {
      icon: "",
      title: "Сценарии репрайсера",
      route: "/repricer-scripts",
      part: "repricer"
    }, {
      icon: "",
      title: "Товары и динамика цен",
      route: "/repricer-products",
      part: "repricer"
    }, {
      icon: "",
      title: "Лог событий",
      route: "/repricer-events-log",
      part: "repricer"
    },
    //==== УПРАВЛЕНИЕ КОНТЕНТОМ
    {
      icon: "content-management.svg",
      title: "Управление контентом",
      href: "https://pim.salesfinder.ru/auth",
      labelColor: "green"
    },
    //==== SF КОНСАЛТИНГ
    {
      icon: "sf-consulting.svg",
      title: "SF Консалтинг",
      href: "https://www.help.salesfinder.ru/consulting",
      labelColor: "green"
    },
    //==== О СЕРВИСЕ
    {
      icon: "about.svg",
      title: "О сервисе",
      route: "",
      part: "about"
    }, {
      icon: "",
      title: "Возможности",
      route: "/features",
      part: "about"
    }, {
      icon: "",
      title: "Тарифы",
      route: "/pricing",
      part: "about"
    }, {
      icon: "",
      title: "Контакты",
      route: "/contact",
      part: "about"
    }, {
      icon: "",
      title: "Обучающие материалы",
      href: "https://help.salesfinder.ru/",
      part: "about"
    }, {
      icon: "",
      title: "Партнерская программа",
      route: "/partner",
      part: "about"
    }]);
    let menu2 = menu.map((i, id) => ({
      ...i,
      id
    }));
    return {
      collapsed: false,
      sidebarOpen: true,
      realMenu: [],
      openedParts: [],
      menu: menu2
    };
  },
  created() {
    // this.updateMenu()
    this.checkConnectionStatus();
    this.setCalendar();
    this.checkPart();
    this.collapsed = !this.sidebarOpen;

    //console.log('541: this.store.state.user.user=', this.$store.state.user.user)
  },

  methods: {
    async checkConnectionStatus() {
      try {
        await this.$store.dispatch("connections/setHasTokens");
      } catch (e) {
        console.error(e);
      }
      try {
        await this.$store.dispatch("reviewManagement/setHasTokens");
      } catch (e) {
        console.error(e);
      }
    },
    setCalendar() {
      this.$store.dispatch("connections/setCalendarBoundaries");
      this.$store.dispatch("connections/setCalendar");
      this.$store.dispatch("reviewManagement/setCalendarBoundaries");
      this.$store.dispatch("reviewManagement/setCalendar");
      this.$store.dispatch("repricer/setCalendarBoundaries");
      this.$store.dispatch("repricer/setCalendar");
    },
    // updateMenu() {
    //   this.$store.state.connections.loading ?
    //       this.realMenu = this.menu.filter(item => (item.id < 15 || item.id > 23)) :
    //       this.realMenu = this.menu
    // },

    toggleSidebar() {
      this.collapsed = this.sidebarOpen;
      this.sidebarOpen = !this.sidebarOpen;
      this.$store.dispatch("user/setSetting", {
        name: "sidebar",
        value: this.sidebarOpen
      });
    },
    toggleSidebarPart(name) {
      if (this.openedParts.includes(name)) {
        this.openedParts = this.openedParts.filter(item => item !== name);
      } else {
        this.openedParts.push(name);
      }
    },
    checkPart() {
      var _this$menu$find;
      this.sidebarOpen = this.$store.state.user.settings["sidebar"] === false ? false : true;
      const part = (_this$menu$find = this.menu.find(item => {
        if (!item.route || !this.$route.path) {
          return false;
        }
        const itemRoute = item.route.replace(/\/$/, "");
        const currentRoute = this.$route.path.replace(/\/$/, "");
        return itemRoute && currentRoute && currentRoute.startsWith(itemRoute);
      })) === null || _this$menu$find === void 0 ? void 0 : _this$menu$find.part;
      if (part) {
        this.openSidebarPart(part);
      }
    },
    openSidebarPart(name) {
      if (!this.openedParts.includes(name)) {
        this.openedParts.push(name);
      }
    },
    mouseEnterSidebar() {
      if (!this.sidebarOpen) {
        this.collapsed = false;
      }
    },
    mouseLeaveSidebar() {
      if (!this.sidebarOpen) {
        this.collapsed = true;
      }
    }
  },
  watch: {
    $route() {
      this.checkPart();
    }
    // '$store.state.connections.loading': function () {
    //   this.updateMenu()
    // },
  }
};