//
//
//

export default {
  activated() {},
  mounted() {
    const el = this.$el;
    if (!el) {
      return;
    }
    const focusEl = el.parentElement.querySelector("[autofocus]");
    if (focusEl && focusEl.focus) {
      focusEl.focus();
    }
  }
};